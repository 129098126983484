import React, { useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Helmet } from 'react-helmet'
import { Modal } from 'reactstrap'
import sortBy from 'lodash/sortBy'

// Functions
import { render, links as makeLinks } from '../../_helpers/markdown'
import { image } from '../../_helpers/images'
import fetchContent from '../../_helpers/fetchContent'
import {
  Container,
  Banner,
  Content,
  Aside,
  PropList,
  PropListItem
} from '../../_shared/Layout'

// Data
import data from '../../../data/site.json'
import projects from '../../../data/projects.json'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const sortNumeric = arr => sortBy(arr, Number)

const Project = withRouter(({ match: { params } }) => {
  const [modal, setModal] = useState(null)

  // Initialising data
  const project = fetchContent(projects, params.projectPath)
  const {
    url,
    year,
    content,
    image,
    links,
    sdgs,
    clients,
    services,
    projects: linkedProjects,
    title,
    subtitle,
    videos,
    extraImages
  } = project

  // Fetch next and previous projects from collection
  const currentIndex = projects.findIndex(inProject => inProject.url === url)
  const prev = projects[currentIndex - 1]
  const next = projects[currentIndex + 1]

  // Render
  return (
    <Container className='c_project '>
      <Helmet>
        <meta name='description' content={content || data.description} />
      </Helmet>

      {/* Modal */}
      <Modal isOpen={modal} toggle={() => setModal(null)}>
        <img data-dismiss='modal' src={modal} width='100%' />
      </Modal>

      <Banner className=' c_project_image'>
        <img src={image} className='img-fluid' />
      </Banner>

      <Aside>
        {/* urls */}
        {!!links && !!links.length && makeLinks(links)}

        {/* linked services */}
        {!!services && !!services.length && (
          <div className='c_project_network'>
            <h3>Aanbod</h3>
            <PropList className='c_project_network_box'>
              {services.map(({ service }, i) => (
                <PropListItem>{service}</PropListItem>
              ))}
            </PropList>
          </div>
        )}

        {/* linked client(s) */}
        {!!clients && !!clients.length && (
          <div className='c_project_network'>
            <h3>Voor</h3>
            <PropList className='c_project_network_box'>
              {clients.map(({ url, client }, i) => (
                <PropListItem>
                  {client}
                  {i + 1 === clients.length && year && ` (${year})`}
                </PropListItem>
              ))}
            </PropList>
          </div>
        )}

        {!!linkedProjects && !!linkedProjects.length && (
          <div className='c_project_projects'>
            <h3>Gelijkaardige projecten</h3>
            <PropList>
              {linkedProjects.map(project => (
                <PropListItem to={`/projecten/${project}`}>
                  {project.label}
                </PropListItem>
              ))}
            </PropList>
          </div>
        )}

        {!!sdgs && !!sdgs.length && (
          <div className='c_project_projects'>
            <h3>Relevante SDG’s</h3>
            <PropList>
              {sortNumeric(sdgs).map(sdg => (
                <img
                  className='sdg'
                  src={`https://assets.eenwereldmetlef.be/sdgs/${sdg}.png`}
                  alt={`SDG ${sdg}`}
                />
              ))}
            </PropList>
          </div>
        )}
      </Aside>

      <Content className=' c_project_content'>
        {/* title and NextPrev */}
        <div className='c_project_titleContainer content-title'>
          {!!prev && (
            <Link to={`/projecten/${prev.url || ''}`}>
              <FontAwesomeIcon icon='caret-left' className='fa-lg' />
            </Link>
          )}
          <h1 className='title'>{title}</h1>
          {!!next && (
            <Link to={`/projecten/${next.url || ''}`}>
              <FontAwesomeIcon icon='caret-right' className='fa-lg' />
            </Link>
          )}
        </div>

        {/* subtitle */}
        {subtitle && <h2>{subtitle}</h2>}

        {/* content */}
        <article className='description'>
          {!!content && render(content || '')}

          {/* video */}
          {videos &&
            videos.map(video => {
              return (
                <div>
                  {render(
                    `@[${video.videoType || 'youtube'}](${video.videoId})`
                  )}
                </div>
              )
            })}

          {/* image gallery */}
          <div className='gallery'>
            {extraImages &&
              extraImages.length &&
              extraImages.map(({ url }, i) => {
                return (
                  <img
                    key={url}
                    alt={`${title} afbeeling ${i + 1}`}
                    className='gallery-item img-thumbnail'
                    onClick={() => setModal(url)}
                    src={url}
                  />
                )
              })}
          </div>
        </article>
      </Content>
    </Container>
  )
})

export default Project
