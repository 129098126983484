import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Clients from './Clients'

const ClientsRouter = ({ match: { path } }) => {
  return (
    <Switch>
      <Route path={path + '/'} component={Clients} />
    </Switch>
  )
}

export default ClientsRouter
