import React from 'react'
import { render } from '../../_helpers/markdown'

import { Container, Content } from '../../_shared/Layout'
import ServiceList from './_ServiceList'

import data from '../../../data/site.json'

const Services = () => {
  return (
    <Container className='c_services'>
      <Content className='c_services_content'>
        {render(data.services_intro)}
      </Content>
      <ServiceList />
    </Container>
  )
}

export default Services
