import React from 'react'
import { Link } from 'react-router-dom'

const Container = ({ children, className = '', ...props }) => (
  <section {...props} className={'container ' + className}>
    {children}
  </section>
)

const Banner = ({ children, caption, straight, className = '', ...props }) => (
  <figure
    {...props}
    className={'banner ' + (!!straight ? 'straight ' : '') + className}
  >
    {children}
    {!!caption && <figcaption>{caption}</figcaption>}
  </figure>
)

const Content = ({ children, className = '', ...props }) => (
  <article {...props} className={'content ' + className}>
    {children}
  </article>
)

const Aside = ({ children, className = '', ...props }) => (
  <aside {...props} className={'aside ' + className}>
    {children}
  </aside>
)

const List = ({ children, className = '', ...props }) => (
  <ol {...props} className={'list ' + className}>
    {children}
  </ol>
)

const ListItem = ({
  children,
  className = '',
  color = 'yellow',
  orientation = '0',
  thumb,
  thumbSize = 'cover',
  ...props
}) => {
  const Linker = props.to ? Link : 'span'
  return (
    <li className={`card card-${color}-${orientation} ${className}`}>
      <Linker className={'a'} {...props}>
        {children}
        {!!thumb && (
          <img
            className='card-thumb'
            src={thumb}
            style={{
              objectFit: thumbSize,
              animationDelay: Math.floor(Math.random() * 400) / 10 + 's'
            }}
          />
        )}
      </Linker>
    </li>
  )
}

const PropList = ({ children, className = '', ...props }) => (
  <ul {...props} className={'proplist ' + className}>
    {children}
  </ul>
)

const PropListItem = ({ children, className = '', ...props }) => {
  const Linker = props.to ? Link : 'span'
  return (
    <Linker {...props} tag='li' className={`proplist-item ${className}`}>
      {children}
    </Linker>
  )
}

export {
  Container,
  Banner,
  Content,
  Aside,
  List,
  ListItem,
  PropList,
  PropListItem
}
