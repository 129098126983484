import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Services from './Services'

const ServicesRouter = ({ match: { path } }) => {
  return (
    <Switch>
      <Route path={path + '/'} component={Services} />
    </Switch>
  )
}

export default ServicesRouter
