import React from 'react'

import { List, ListItem } from '../../_shared/Layout'

import team from '../../../data/team-members.json'

export default ({ active }) => (
  <List className='c_team_list'>
    {team
      .filter(({ published }) => !!published)
      .map(({ url, title, thumb }, i) => (
        <ListItem
          to={`/wij/${url}`}
          className={`c_team_item`}
          color={active === url ? 'orange' : 'green'}
          orientation={i % 4}
          thumb={thumb}
          key={url}
        >
          {title}
        </ListItem>
      ))}
  </List>
)
