import React from 'react'

import { Container, Content } from '../../_shared/Layout'
import ProjectList from './_ProjectList'

import data from '../../../data/site.json'
import { render } from '../../_helpers/markdown'

const Projects = () => {
  return (
    <Container className='c_projects '>
      <Content className='c_projects_content '>
        {render(data.projects_intro)}
      </Content>
      <ProjectList />
    </Container>
  )
}

export default Projects
