import React from 'react'

// Functions
import { List, ListItem } from '../../_shared/Layout'

// Data
import clients from '../../../data/clients.json'

export default ({ active }) => (
  <List className='c_clients_list'>
    {clients
      .filter(({ published }) => !!published)
      .map(({ url, title, photo }, i) => (
        <ListItem
          // to={`/klanten/${url}`}
          className={`c_clients_item`}
          color={!!active && active === url ? 'green' : 'orange'}
          orientation={i % 4}
          thumb={photo}
          thumbSize={'contain'}
          key={url}
        >
          {title}
        </ListItem>
      ))}
  </List>
)
