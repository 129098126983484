import React from 'react'
import { hydrate, render } from 'react-dom'

import App from '../src/ui/App'

import '../src/ui/_helpers/icons'

// New render function to get snapshot of dynamic pages
const rootElement = document.getElementById('root')
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement)
} else {
  render(<App />, rootElement)
}
