import React from 'react'
import { Container, Content } from '../../_shared/Layout'

const Redirector = () => {
  // change this one as needed. Currently aimed at getresponse landing page
  const url = 'https://dekat.gr8.com'
  // this catapults you away from the site
  window.location.href = url
  return (
    <Container>
      <Content>We sturen je even door...</Content>
    </Container>
  )
}

export default Redirector
