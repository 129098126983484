import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Contact from '../Pages/Contact'
import Credo from '../Pages/Credo'
import Team from './Team'
import TeamMember from './TeamMember'

const TeamRouter = ({ match: { path } }) => {
  return (
    <Switch>
      <Route path={path + '/contact'} component={Contact} />
      <Route path={path + '/credo'} component={Credo} />
      <Route path={path + '/:teamMemberPath'} component={TeamMember} />
      <Route path={path + '/'} component={Team} />
    </Switch>
  )
}

export default TeamRouter
