import React, { useState, useContext, createContext, useEffect } from 'react'
import { withRouter } from 'react-router'
import { NavLink } from 'react-router-dom'

// import pages from '../../../data/pages.json'

const paths = [
  { link: 'aanbod', label: 'aanbod' },
  { link: 'projecten', label: 'projecten' },
  { link: 'klanten', label: 'klanten' },
  { link: 'wij', label: 'wij' }
  // { link: 'contact', label: 'contact' }
  // { link: 'blog', label: 'blog' }
]

const isOpen = false
const setOpen = () => null
export const NavContext = createContext({ isOpen, setOpen })

const NavResetter = withRouter(({ children, ...props }) => {
  const { isOpen, setOpen } = useContext(NavContext)
  const [prevProps, setProps] = useState({})
  useEffect(() => {
    if (props.location !== prevProps.location) {
      setOpen(false)
      setProps(props)
    }
  })
  return children
})

export const NavProvider = ({ children }) => {
  const [isOpen, setOpen] = useState(false)
  return (
    <NavContext.Provider
      value={{ isOpen, setOpen: (target = !isOpen) => setOpen(target) }}
    >
      <NavResetter>{children}</NavResetter>
    </NavContext.Provider>
  )
}

export const Toggle = () => {
  const { isOpen, setOpen } = useContext(NavContext)
  return (
    <div className={'nav-item'} onClick={() => setOpen(!isOpen)}>
      &times;
    </div>
  )
}

// pages.forEach(
//   page => !!page && paths.push({ link: page.url, label: page.title })
// )

const Navbar = () => {
  const { isOpen, setOpen } = useContext(NavContext)
  return (
    <nav className={'navbar' + (isOpen ? ' navbar__open' : '')}>
      <button className='nav-toggle' onClick={() => setOpen(!isOpen)}>
        ☰
      </button>
      <ul className='nav navbar-right ml-auto'>
        {paths.map(path => {
          return (
            <NavLink className='nav-item' to={`/${path.link}`} key={path.link}>
              {path.label}
            </NavLink>
          )
        })}
      </ul>
    </nav>
  )
}

export default Navbar
