import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Projects from './Projects'
import Project from './Project'

const ProjectsRouter = ({ match: { path } }) => {
  return (
    <Switch>
      <Route path={path + '/:projectPath'} component={Project} />
      <Route path={path + '/'} component={Projects} />
    </Switch>
  )
}

export default ProjectsRouter
