import React from 'react'

import { render } from '../../_helpers/markdown'
import { Container, Content, List, ListItem } from '../../_shared/Layout'
import ClientList from './_ClientList'

import data from '../../../data/site.json'

const Clients = () => {
  return (
    <Container className='c_clients'>
      <Content className='c_clients_content'>
        {render(data.clients_intro)}
      </Content>
      <ClientList />
    </Container>
  )
}

export default Clients
