import React from 'react'

import { Container, Content } from '../../_shared/Layout'

import TeamList from './_TeamList'

import data from '../../../data/site.json'
import { render } from '../../_helpers/markdown'

const Team = () => {
  return (
    <Container className='c_team'>
      <Content className='c_team_content'>{render(data.team_intro)}</Content>
      <TeamList />
    </Container>
  )
}

export default Team
