import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import { NavContext, Toggle } from '../Navbar/Navbar'

const Footer = () => {
  const { isOpen } = useContext(NavContext)
  return (
    <footer id='footer'>
      <nav className={'navbar' + (isOpen ? ' navbar__open' : '')}>
        <ul className='nav navbar-right ml-auto'>
          <Link className='nav-item' to='/' key={'home'}>
            <img src='/images/lef_logo.png' alt='Een wereld met LEF (logo)' />
          </Link>
          <Toggle />
        </ul>
      </nav>
    </footer>
  )
}

export default Footer
