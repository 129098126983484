import React, { useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactWordcloud from 'react-wordcloud'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/scale.css'

// Functions
import { render } from '../../_helpers/markdown'
import fetchContent from '../../_helpers/fetchContent'
import {
  Container,
  Banner,
  Content,
  PropList,
  PropListItem
} from '../../_shared/Layout'

// Data
import data from '../../../data/site.json'
import teammembers from '../../../data/team-members.json'

const TeamMember = withRouter(({ match: { params } }) => {
  // Initialising data
  const teammember = fetchContent(teammembers, params.teamMemberPath)

  // Fetch next and previous members from collection
  const currentIndex = teammembers.findIndex(m => m.url === teammember.url)
  const prev = teammembers[currentIndex - 1]
  const next = teammembers[currentIndex + 1]

  // wordcloudConfig
  const words = teammember.wordcloud
  const options = {
    colors: ['#1e6e88', '#676767', '#1f1e1d', '#ed7347', '#89c27b', '#d4c546'],
    rotations: 2,
    rotationAngles: [-90, 0],
    fontFamily: 'Montserrat', // 'Libre Baskerville'
    fontWeight: 'bold',
    fontSizes: [12, 48],
    enableTooltip: false,
    padding: 20
  }

  // Render
  return (
    <Container className='c_teammember'>
      <Helmet>
        <meta
          name='description'
          content={teammember.content || data.description}
        />
      </Helmet>

      <Banner straight className='c_teammember_image_container'>
        {/* worcloud */}
        {words && !!words.length && (
          <ReactWordcloud words={words} options={options} />
        )}
      </Banner>

      <Content className='c_teammember_content'>
        <div className='c_project_titleContainer content-title'>
          {!!prev && (
            <Link to={`/wij/${prev.url || ''}`}>
              <FontAwesomeIcon icon='caret-left' className='fa-lg' />
            </Link>
          )}
          <h1 className='title'>{teammember.title}</h1>
          {!!next && (
            <Link to={`/wij/${next.url || ''}`}>
              <FontAwesomeIcon icon='caret-right' className='fa-lg' />
            </Link>
          )}
        </div>

        {render(teammember.content || '')}

        {/* linked services */}
        {/* {teammember.services && (
          <div className='c_teammember_network'>
            <h3>Aanbod</h3>
            <PropList>
              {teammember.services.map(({ service }) => (
                <PropListItem key={service} to={`/aanbod/${service}`}>
                  {service}
                </PropListItem>
              ))}
            </PropList>
          </div>
        )} */}
      </Content>
    </Container>
  )
})

export default TeamMember
