import React from 'react'

import { render } from '../../_helpers/markdown'
import { Container, Banner, Content } from '../../_shared/Layout'

import data from '../../../data/site.json'

const Home = () => {
  return (
    <Container id='home' className='c_home'>
      <Banner className='c_home_image'>
        <img
          src={data.home_image}
          alt='home afbeelding'
          className='img-fluid'
        />
      </Banner>
      <Content className='c_home_content'>{render(data.home)}</Content>
    </Container>
  )
}

export default Home
