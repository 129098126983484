import React from 'react'
import MarkdownIt from 'markdown-it'
import mila from 'markdown-it-link-attributes'
import MarkdownItAttrs from 'markdown-it-attrs'
import MarkdownItVideo from 'markdown-it-video'

const md = new MarkdownIt({ html: true, linkify: true, typographer: true })
  .use(mila, {
    pattern: /^https:/,
    attrs: {
      target: '_blank',
      rel: 'noopener'
    }
  })
  .use(MarkdownItAttrs)
  .use(MarkdownItVideo)

const render = (content = '', options = {}) => {
  const { Tag = 'div', className = '', ...props } = options

  return (
    <Tag
      {...props}
      className={className + ' markdown'}
      dangerouslySetInnerHTML={{ __html: md.render(content) }}
    />
  )
}

const links = list => {
  return (
    <ul className='linklist'>
      {list.map(({ url, link, label }, key) => {
        return (
          <li key={key} className='linkbox'>
            <a href={url || link} target='_blank' rel='noopener noreferer'>
              {label || link || url}
            </a>
          </li>
        )
      })}
    </ul>
  )
}

export { md, render, links }
