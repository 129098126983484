import React from 'react'
import sortBy from 'lodash/sortBy'
import { List, ListItem } from '../../_shared/Layout'

import projects from '../../../data/projects.json'

const sortedProjects = sortBy(projects, 'year').reverse()

export default ({ active }) => (
  <List className=' c_projects_list'>
    {sortedProjects
      .filter(({ published }) => !!published)
      .map(({ url, title, thumbnail }, i) => (
        <ListItem
          to={`/projecten/${url}`}
          className={`c_projects_item`}
          color={active === url ? 'orange' : 'yellow'}
          orientation={i % 4}
          thumb={thumbnail}
          key={url}
        >
          {title}
        </ListItem>
      ))}
  </List>
)
