import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

// import ReactGA from 'react-ga'
import { Helmet } from 'react-helmet'

// Components
import Footer from './_shared/Footer/Footer'
import Navbar, { NavProvider } from './_shared/Navbar/Navbar'

import Home from './Components/Home/Home'
import ClientsRouter from './Components/Clients'
import ProjectsRouter from './Components/Projects'
import Redirector from './Components/Other/Redirector'
import ServicesRouter from './Components/Services'
import TeamRouter from './Components/Team'

// Style
import '../styles/main.scss'

// Data
import data from '../data/site.json'

const App = () => {
  // if (data.google_tracking_id) ReactGA.initialize(data.google_tracking_id) // doesn't work but should?
  return (
    <div>
      <Helmet>
        <meta name="description" content={data.description} />
      </Helmet>
      <BrowserRouter>
        <NavProvider>
          <Navbar />
          <Switch>
            <Route path="/klanten" component={ClientsRouter} />
            <Route path="/projecten" component={ProjectsRouter} />
            <Route path="/aanbod" component={ServicesRouter} />
            <Route path="/wij" component={TeamRouter} />
            <Route path="/qr-online-aanbod" component={Redirector} />
            <Route path="/" exact component={Home}></Route>
          </Switch>
          <Footer />
        </NavProvider>
      </BrowserRouter>
    </div>
  )
}

export default App
